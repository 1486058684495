<template lang="pug">
  v-app
    .d-flex.mb-0(:class="mobileView ? 'flex-column' : 'flex-row'")
      img.image(src="@/assets/images/quiz/quiz_main_1.png")
      .d-flex.flex-column
        img.image2(src="@/assets/images/quiz/quiz_main_2.png")
        v-btn(
          class="m-auto my-8"
          color="primary"
          dense
          v-bind="size"
          to="/quiz-main/1"
        ) 
          b {{ $t("quiz.jom.text") }}
</template>

<script>
export default {
  computed: {
    size() {
      const size = { xs: "large", sm: "large", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    // goQuiz() {
    //   if (this.$store.getters.isQuizDone)
    //     this.$router.push({ name: "QuizDone" });
    //   else if (this.$store.getters.isQuiz2Done)
    //     this.$router.push({ name: "Quiz3" });
    //   else if (this.$store.getters.isQuiz2Done)
    //     this.$router.push({ name: "Quiz2" });
    //   else this.$router.push({ name: "Quiz1" });
    // }
  }
};
</script>

<style lang="sass" scoped>
.v-application
  min-height: 90vh !important
  overflow: hidden !important

.image
  // margin-top: 5vw
  width: 50%
  margin: auto 0
  margin-bottom: 0

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: 80%
    margin: auto

.image2
  width: 75%
  margin: 4rem auto
  // margin-top: 10vw

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: 60%
    margin: auto
    margin-top: 2rem
</style>
